export const ENV_CONFIG_AUTH0_DOMAIN = import.meta.env
  .VITE_AUTH0_DOMAIN as string;
export const ENV_CONFIG_AUTH0_CLIENT_ID = import.meta.env
  .VITE_AUTH0_CLIENT_ID as string;
export const ENV_CONFIG_AUTH0_AUDIENCE = import.meta.env
  .VITE_AUTH0_AUDIENCE as string;

export const ENV_CONFIG_API_PM_BASE_URL = import.meta.env
  .VITE_API_PM_BASE_URL as string;
export const ENV_CONFIG_API_BMS_BASE_URL = import.meta.env
  .VITE_API_BMS_BASE_URL as string;
export const ENV_CONFIG_API_BMS_ECS_BASE_URL = import.meta.env
  .VITE_API_BMS_ECS_BASE_URL as string;
export const ENV_CONFIG_CLIENT_PORTAL_API_BASE_URL = import.meta.env
  .VITE_CLIENT_PORTAL_API_BASE_URL as string;

export const ENV_CONFIG_PUBLIC_POSTHOG_KEY = import.meta.env
  .VITE_PUBLIC_POSTHOG_KEY as string;
export const ENV_CONFIG_PUBLIC_POSTHOG_HOST = import.meta.env
  .VITE_PUBLIC_POSTHOG_HOST as string;
export const ENV_CONFIG_PUBLIC_POSTHOG_PROXY_API_HOST = import.meta.env
  .VITE_PUBLIC_POSTHOG_PROXY_API_HOST as string;
export const ENV_CONFIG_STREAM_CLIENT_ID = import.meta.env
  .VITE_STREAM_CLIENT_ID as string;
export const ENV_CONFIG_FLATIRONS_FUSE_ORG_API_KEY = import.meta.env
  .VITE_FLATIRONS_FUSE_ORG_API_KEY as string;
export const ENV_CONFIG_FLATIRONS_FUSE_IMPORT_CLIENT_TEMPLATE_ID = import.meta
  .env.VITE_FLATIRONS_FUSE_IMPORT_CLIENT_TEMPLATE_ID as string;
export const ENV_CONFIG_GMAIL_CLIENT_ID = import.meta.env
  .VITE_GMAIL_CLIENT_ID as string;
export const ENV_CONFIG_MICROSOFT_CLIENT_ID = import.meta.env
  .VITE_MICROSOFT_CLIENT_ID as string;
export const ENV_CONFIG_APRYSE_LICENSE_KEY = import.meta.env
  .VITE_APRYSE_LICENSE_KEY as string;

export const ENV_CONFIG_AADHAAR_SIGNATURE_FORM_ACTION_URL = import.meta.env
  .VITE_AADHAAR_SIGNATURE_FORM_ACTION_URL as string;

export const ENV_CONFIG_CLERK_PUBLISHABLE_KEY = import.meta.env
  .VITE_CLERK_PUBLISHABLE_KEY as string;

export const ENV_CONFIG_VITE_ENVIRONMENT = import.meta.env
  .VITE_ENVIRONMENT as string;

export const ENV_VITE_PDFJS_EXPRESS_UTILS_CLIENT_KEY = import.meta.env
  .VITE_PDFJS_EXPRESS_UTILS_CLIENT_KEY as string;

export const ENV_VITE_PDFJS_EXPRESS_UTILS_SERVER_KEY = import.meta.env
  .VITE_PDFJS_EXPRESS_UTILS_SERVER_KEY as string;

export const ENV_CONFIG_VITE_USECSV_KEY = import.meta.env
  .VITE_USECSV_KEY as string;

export const ENV_CONFIG_BUILD_APP_NAME =
  (import.meta.env.VITE_BUILD_APP_NAME?.trim() || undefined) as
    | ReactRootAppName
    | undefined;

export const ENV_CONFIG_INTERCOM_APP_ID = import.meta.env
  .VITE_INTERCOM_APP_ID as string;

export const ENV_CONFIG_UPLOADCARE_KEY = import.meta.env
  .VITE_UPLOADCARE_KEY as string;

export const ENV_CONFIG_UPLOADCARE_CDN_CNAME = import.meta.env
  .VITE_UPLOADCARE_CDN_CNAME as string;

export const ENV_CONFIG_CLIENT_PORTAL_LINK = import.meta.env
  .VITE_CLIENT_PORTAL_LINK as string;

export const ENV_CONFIG_REPORTING_CLIENT_PROFITABILITY_DASHBOARD_ID =
  import.meta.env.VITE_REPORTING_CLIENT_PROFITABILITY_DASHBOARD_ID as string;
export const ENV_CONFIG_REPORTING_CLIENT_TIME_DASHBOARD_ID = import.meta.env
  .VITE_REPORTING_CLIENT_TIME_DASHBOARD_ID as string;
export const ENV_CONFIG_REPORTING_TEAM_PROFITABILITY_DASHBOARD_ID = import.meta
  .env.VITE_REPORTING_TEAM_PROFITABILITY_DASHBOARD_ID as string;
export const ENV_CONFIG_REPORTING_TEAM_UTILIZATION_DASHBOARD_ID = import.meta
  .env.VITE_REPORTING_TEAM_UTILIZATION_DASHBOARD_ID as string;

export enum ReactRootAppName {
  Pm = 'PM',
  PmClient = 'PM_CLIENT_PORTAL',
}
